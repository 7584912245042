import http from "./axios";
export const startRaffle = () => {
    return http.request({
        url: "/raffle/startRaffle",
        method: "get",
    })
}

export const getUserChance=()=>{
    return http.request({
        url: "/raffle/getUserChance",
        method: "get",
    })
}

export const exeVeteranRegression=()=>{
    return http.request({
        url: "/raffle/exeVeteranRegression",
        method: "get",
    })
}


export const selUserTaskStatus=()=>{
    return http.request({
        url: "/raffle/selUserTaskStatus",
        method: "get",
    })
}


export const exeBuyVip=()=>{
    return http.request({
        url: "/raffle/exeBuyVip",
        method: "get",
    })
}

export const exeBuySVip=()=>{
    return http.request({
        url: "/raffle/exeBuySVip",
        method: "get",
    })
}


export const exePro=()=>{
    return http.request({
        url: "/raffle/exePro",
        method: "get",
    })
}


