export const headerText = [
    "国庆七天签到 超多好礼上线即送",
    "国庆七天签到 超多好礼上线即",
    "国庆七天签到 超多好礼上线",
    "国庆七天签到 超多好礼上",
    "国庆七天签到 超多好礼",
    "国庆七天签到 超多好",
    "国庆七天签到 超多",
    "国庆七天签到 超",
    "国庆七天签到",
    "国庆七天签",
    "国庆七天",
    "国庆七",
    "国庆",
    "国",
    "",
    "老",
    "老兵",
    "老兵上",
    "老兵上线",
    "老兵上线即",
    "老兵上线即可",
    "老兵上线即可获",
    "老兵上线即可获得",
    "老兵上线即可获得7",
    "老兵上线即可获得7天",
    "老兵上线即可获得7天会",
    "老兵上线即可获得7天会员",
    "老兵上线即可获得7天会员权",
    "老兵上线即可获得7天会员权益",
    "老兵上线即可获得7天会员权益！",
    "老兵上线即可获得7天会员权益",
    "老兵上线即可获得7天会员权",
    "老兵上线即可获得7天会员",
    "老兵上线即可获得7天会",
    "老兵上线即可获得7天",
    "老兵上线即可获得7",
    "老兵上线即可获得",
    "老兵上线即可获",
    "老兵上线即可",
    "老兵上线",
    "老兵上",
    "老兵",
    "老",
    "",
    "国",
    "国庆",
    "国庆七",
    "国庆七天",
    "国庆七天签",
    "国庆七天签到",
    "国庆七天签到 超",
    "国庆七天签到 超多",
    "国庆七天签到 超多好",
    "国庆七天签到 超多好礼",
    "国庆七天签到 超多好礼上",
    "国庆七天签到 超多好礼上线",
    "国庆七天签到 超多好礼上线即",
    "国庆七天签到 超多好礼上线即送",
];
