import './App.css';
// import {useAppDispatch, useAppSelector} from "./store";
import {RouterProvider} from "react-router-dom";
import router from "./router";
import MySider from "./components/sider/index"
import { Layout } from "antd";
import RouterContext from './router/routerContext';
import { Image } from 'antd';
import {useEffect, useState} from "react";
import {headerText} from "./common/headerText"

// import myWebsocket from "./common/websocket/myWebsocket";
// import {useEffect} from "react";
// import {setServerList,setMyServerList} from "./store/moduels/listStore"


function App() {
  const { Content,Sider,Header} = Layout;
  const currentRoute = router.routes.find((route) => route.path === window.location.pathname )
  let hideMenu
  if (currentRoute === undefined){
    if (window.location.pathname === "/login"){
      hideMenu = true;
    }else{
      hideMenu = false;
    }
  }else{
    hideMenu = currentRoute.hideMenu
  }

  // const [webSocket, sendMessage, lastMessage] =myWebsocket();
  // const dispatch = useAppDispatch();
  // const {websocket_serverList} = useAppSelector(state=>state.list)
  // useEffect(() => {
  //   if(lastMessage){
  //     switch(lastMessage.data){
  //       case "serverList":{
  //         if (!websocket_serverList){
  //           dispatch(setServerList(true))
  //           dispatch(setMyServerList(true))
  //         }
  //         break;
  //       }
  //     }
  //   }
  // }, [lastMessage]);

    // header文字的处理
    const [textIndex, setTextIndex] = useState(0); // 初始化索引为 0
    useEffect(() => {
        const interval = setInterval(() => {
            setTextIndex((prevIndex) => (prevIndex + 1) % headerText.length); // 循环更新索引
        }, 500); // 每 1000 毫秒切换一次

        return () => clearInterval(interval); // 清除定时器
    }, []);


  // 对光标的处理
    const [showCursor, setShowCursor] = useState(true);
    useEffect(() => {
        const cursorInterval = setInterval(() => {
            setShowCursor(prev => !prev); // 切换光标显示状态
        }, 500); // 每500毫秒切换一次
        return () => clearInterval(cursorInterval); // 清除定时器
    }, []);

  return (
      <RouterContext.Provider value={router}>
        <Layout className="layout">
          <Header className="header">
              <div className="headerImg">
                  <Image preview={false} width={200} src="https://r0csgo.com/2024_cw/logo1.png"></Image>
              </div>
              {/*<div className="headerTitle">{headerText[textIndex]}</div>*/}
              {/*{showCursor && <div style={{color:"#fff"}} className="cursor">|</div>}*/}
          </Header>
        <Layout className="main">
          {!hideMenu && <Sider className="sider">
            <MySider/>
          </Sider>}
          <Content>
            <RouterProvider router={router}/>
          </Content>
        </Layout>
        </Layout>
      </RouterContext.Provider>
  );
}

export default App;
