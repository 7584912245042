import axios from 'axios';
import { encryptParams } from '../utils/encryption'
import {message} from "antd";
const service =axios.create({
    timeout: 3000
    })

const baseURL=  'https://activity-2024-10-1.r0play.com/api/';
// const baseURL=  'http://localhost:8080';

// request拦截器
service.interceptors.request.use(async config => {

// 获取CToken
//     const CToken = localStorage.getItem('CToken');
//     if (CToken) {
//         config.headers['Authorization'] = `Bearer ${CToken}`
//     }

    // 获取平台的token
    await fetch('http://127.0.0.1:54427/get_user_info').then(res =>{
     if(!res.ok){
         throw new Error(`HTTP error! status`);
     }
        return res.json()
    }
    ).then(data=>{
        config.headers['Authorization'] = `Bearer ${data.data.token}`
    }).catch(error=>{
        message.error('请先打开并 登入R0对战平台')
        return Promise.reject(error);
    })

    // config.headers['Authorization'] = `Bearer 1c9b3d5352a7208f6ef0670343d7f784`

// 在发送请求之前做些什么，例如加入token
    if (config.url && !config.url.startsWith('http')){
        config.url = baseURL + config.url
    }
        if (config.method === 'post') {
            if (config.data===undefined){
                config.data={}
            }
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = encryptParams(config.data)
        } else if (config.method === 'get') {
            if (config.params===undefined){
                config.params={}
            }
            const encryptedData = encryptParams(config.params)
            const url =
                config.url +
                '?d=' +
                encodeURIComponent(encryptedData.d) +
                '&t=' +
                encryptedData.t +
                '&r=' +
                encryptedData.r +
                '&s=' +
                encryptedData.s
            config.url = url
            config.params = ''
        }
    return config;
}, error => {
    return Promise.reject(error);
})

// response拦截器
service.interceptors.response.use(response => {
    const {msg, code} = response.data
    if (code !==200){
        message.error(msg)
        // if (code === 401) {  //这样会直接刷新页面
        //     if (window.location.pathname !== '/login') {
        //         window.location.href = '/login'
        //         message.error(msg)
        //     }
        // }else {
        //     message.error(msg)
        // }
    }
    return response.data;
}, (error) => {
    if (error.code === 'ECONNABORTED'){
        message.error('请求超时，请稍后再试')
    }
    return Promise.reject(error);
});

export default service;

