import {configureStore} from '@reduxjs/toolkit'
import userReducer from './moduels/userStore'
import listStore from "./moduels/listStore";
import {useDispatch,useSelector} from "react-redux";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from '@reduxjs/toolkit';

// 配置 persistConfig
const persistConfig = {
    key: 'root',
    storage,
};

// 使用 persistReducer 包装 reducer
const rootReducer = combineReducers({
    user: userReducer,
    list: listStore,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
});

const persistor = persistStore(store);


export { store, persistor };
export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;
