import "./style/index.scss"
import {BlockOutlined} from "@ant-design/icons";
import {useContext} from "react";
import RouterContext from "../../router/routerContext";
const Sider = () => {
    const router = useContext(RouterContext);

    const listHashClick = (index) => {
        if (index === 0) {
            router.navigate('/')
        }
        else if (index === 1) {
            router.navigate('/debris')
        }else if (index === 2) {
            router.navigate('/shopping')
        }
    }

    return (
        <div className="sider">
            <div className="container">

                {/*国庆活动*/}
                {/*<div className="box" onClick={() => listHashClick(0)} title="签到中心">*/}
                {/*    <HomeOutlined className="iconHover" />*/}
                {/*    <div className="title">签到中心</div>*/}
                {/*</div>*/}


                {/*<div className="box" onClick={() => listHashClick(1)} title="抽奖中心">*/}
                {/*    <GiftOutlined className="iconHover"/>*/}
                {/*    <div className="title">抽奖中心</div>*/}
                {/*</div>*/}


                <div className="box" onClick={() => listHashClick(0)} title="碎片商城">
                    <BlockOutlined className="iconHover"/>
                    <div className="title">碎片商城</div>
                </div>


                {/*社区服务器*/}
                {/*<div className="box" onClick={() => listHashClick(0)} title="服务器列表">*/}
                {/*    <UnorderedListOutlined className={activeIndex === 0 ? "iconHover" : "icon"}/>*/}
                {/*</div>*/}

                {/*<div className="box" onClick={() => listHashClick(1)} title="概览">*/}
                {/*    <AppstoreOutlined className="iconHover"/>*/}
                {/*</div>*/}

            </div>
        </div>
    )
}

export default Sider
