import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from "antd"
import App from './App'
import { store, persistor } from './store';
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider theme={{
          components: {
                Table: {
                      colorBgContainer: '#110d27',
                      colorTextHeading: '#ffffff',
                      colorText: '#ffffff',
                      fontSize: 18,
                },
                Tabs: {
                      colorBgContainer: 'transparent',
                      colorText: '#fff',
                      fontSize: 23,
                },
                Modal: {
                      contentBg: '#2d2e2f',
                      headerBg: '#2d2e2f',
                      titleColor: '#FFF',
                      colorBgTextActive: '#FFF',
                      colorIcon: '#FFF',
                      colorIconHover: '#FFF',
                },
                Input:{
                   colorText:'#FFF',
                   colorTextDescription:'white'
                },
                Switch:{
                    trackHeight: 35,
                    trackMinWidth: 70,
                    handleSize: 30,
                },
                Select:{
                    colorBgContainer: 'transparent',
                    colorText: '#FFF',
                    colorBgElevated: '#110d27',
                    colorTextPlaceholder: '#FFF',
                    fontSize: 20,
                }
          },
    }}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </ConfigProvider>
);

