import {createSlice} from "@reduxjs/toolkit"

const userSlice = createSlice({
    name: "user",
    initialState:{
        uid: null,
        username: null,
        avatar: null,
        steamId: null,
        rank: null,
        CToken: null
    },
    reducers: {
        setUser: (state, action) => {
            const {uid, username, avatar, steamId, rank, CToken} = action.payload
            state.uid = uid
            state.username = username
            state.avatar = avatar
            state.steamId = steamId
            state.rank = rank
            state.CToken = CToken
        }
    }
})

export const {setUser} = userSlice.actions
export default userSlice.reducer
