import CryptoJS from 'crypto-js'
import 'crypto-js/sha256'
import { JSEncrypt } from 'jsencrypt'
export const  encryptParams = (params) => {
    // const obj = JSON.parse(params)
    const keys = Object.keys(params)
    keys.push('timestamp')
    keys.sort()
    let key_data = ''
    for (let i = 0; i < keys.length; i++) {
        key_data = key_data + keys[i]
    }
    const sha256 = CryptoJS.SHA256(key_data).toString(CryptoJS.enc.Hex)
    const t = Math.floor(new Date().getTime() / 1000)
    const sha_s2 = sha256 + t
    // eslint-disable-next-line no-undef
    const num10 = BigInt('0x' + sha_s2).toString(10)
    const r = Math.ceil(Math.random() * 1000000)
    let s = CryptoJS.MD5(num10 + '' + r).toString(CryptoJS.enc.Hex)
    s = CryptoJS.MD5(s + '' + r).toString(CryptoJS.enc.Hex)
    const d = rsa_encode(JSON.stringify(params))
    return {
        d,
        t,
        r,
        s,
    }
}

const rsa_encode = (data) => {  // rsa加密
    const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnXFec6BgUeuZ8R445ivo
NgBl6SgMPuT7v8qLCXW+Gn5Gebwn47pMGOMsEXnBVFUSh1Y2o8/y0xBRHHKekRgN
/38paxqZRoZzbuynRNP8yxcYePmFqW/Zb6Ea2Cgc/+nI6L151DbwesqHO6HiAftj
Yxn33oBY3qxWKF546XH4Hua7zX137gq8Vri/rFuphYwYbBmoHShTPsHch9xMe6Ie
mKusF+mC7ARQBBt1Jw6bkItnISOKsLhrXWHb5Er+H1u13MCNC2xyk0WCmGtJWaO5
ZQxbdDBHkyaSsPexZefsuGwKkIEuqa2RiN91Jo3hq1mxgo2jcXdvz6fhlhYyd4Bp
PwIDAQAB
-----END PUBLIC KEY-----`
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(publicKey)
    const chunkSize = 190
    let encrypted = ''
    for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize)
        const encryptedChunk = encrypt.encrypt(chunk)
        if (encryptedChunk) {
            encrypted += encryptedChunk + ';' // 使用分隔符拼接
        }
    }

    return encrypted.slice(0, -1)
}
