import { createBrowserRouter} from  "react-router-dom";
//import First from '../pages/first/first'
//import Debris from '../pages/debris/debris'
import Shopping from '../pages/shopping/shopping'
//import Overview from "../pages/overview/overview";
//import DetailsMode from "../components/detailsMode/detailsMode";
// import Home from '../pages/home/home'
// import AddServer from '../pages/addServer/addServer'
// import Login from '../pages/login/login'
// import Setting from '../pages/setting/setting'
// import Overview from "../pages/overview/overview";
// import DetailsMode from "../components/detailsMode/detailsMode"
const  routes= [
    // 活动首页
    // {
    //     path: "/",
    //     element: <First/>,
    // },
    //  抽奖中心
    // {
    //     path: "/debris",
    //     element: <Debris/>,
    // },shopping
    {
        path: "/",
        element: <Shopping/>,
    },
    // 社区服务器
    // {
    //     path: "/home",
    //     element: <Home/>,
    // },
    // {
    //     path: "/login",
    //     element: <Login/>,
    //     hideMenu: true,
    // },
    // {
    //     path: "/addServer",
    //     element: <AddServer/>,
    // },{
    //     path: "/setting/:id",
    //     element: <Setting/>,
    // },
    // {
    //     path: "/overview",
    //     element: <Overview/>,
    //     children: [
    //         {
    //             path: "detail/:index",
    //             element: <DetailsMode/>,
    //         },
    //     ],
    // }
]


const router = createBrowserRouter(routes)
export default router;
