import {createSlice} from "@reduxjs/toolkit"
const listSlice = createSlice({
    name: "list",
    initialState:{
        websocket_serverList:false,
        websocket_myServerList:false
    },
    reducers:{
        setServerList:(state,action)=>{
            state.websocket_serverList = action.payload
        },
        setMyServerList:(state,action)=>{
            state.websocket_myServerList = action.payload
        }

    }
})

export const {setServerList,setMyServerList} = listSlice.actions
export default listSlice.reducer
