import noshow from "../../common/images/noshow.png"
import vip from "../../common/images/vip.png"
import yixiang from "../../common/images/yixiang.png"
import suibei from "../../common/images/suibei.png"
import shubiao from "../../common/images/shubiao.jpg"
import shubiaodian from "../../common/images/shubiaodian.png"
import jianpan from "../../common/images/jianpan.jpg"
import r_coin from "../../common/images/r_coin.png"
import suipian from "../../common/images/suipian.png"
import feicueidao from "../../common/images/feicueidao.png"
import goldusp from "../../common/images/goldusp.png"
import zhijindao from "../../common/images/zhijindao.png"
import {selMyInventory, exchangePiece, exeUsePiece,exeUserStore,transformPiece,selectWeapon} from "../../api/shopping"
import {Image, InputNumber, message,Modal,Radio } from "antd";
import {useEffect, useState} from "react";
import {getUserChance} from "../../api/raffle";
import "./style/shopping.css"
import {DoubleRightOutlined} from "@ant-design/icons";


const Shopping=()=>{
    const [pieceNum,setPieceNum]=useState(0)
    function handleGetUserChance(){
        getUserChance().then(res=>{
            if(res.code===200){
                setPieceNum(res.data.pieceNum)
            }
        }).catch(err=>{
            message.error("请先打开并登入R0对战平台")
        })
    }


    function handleExchange(sourceId){
        exeUsePiece(sourceId).then(res=>{
            if(res.code===200){
                message.success("兑换成功！")
                handleGetInventory()  //刷新库存
                handleGetUserChance()  //刷新碎片数量
            }
        })

    }


    const [userStore,setUserStore]=useState([])
    function handleGetInventory(){
        selMyInventory().then(res=>{
            setUserStore(res.data)
        })
    }

    function returnImg(img){
        switch (img){
            case "vip":return vip
            case "noshow":return noshow
            case "shubiaodian":return shubiaodian
            default:return null
        }
    }

    function exchangeFragments(item){
        if(item.source===1){   //表示是签到获取的
            message.error("签到获取的不能兑换哦！")
            return
        }
        if(item.source===2){
            message.error("碎片商城兑换的不能兑换成碎片哦！")
            return
        }
        exchangePiece(item.id).then(res=>{
            if(res.code===200){
                message.success("兑换成功！")
                handleGetInventory()  //刷新库存
                handleGetUserChance()  //刷新碎片数量
            }
        })
    }

    const [inventoryId,setInventoryId]=useState(0)
    function exeUseInventory(item){
        exeUserStore(item.id).then(res=>{
            if(res.code===200){
                if(res.data==="select"){
                    setIsModalOpen(true);
                    setInventoryId(item.id)
                }else{
                    message.success(res.data)
                    handleGetInventory()  //刷新库存
                    handleGetUserChance()  //刷新碎片数量
                }
            }
        })
    }

    function r0moneyToPiece(){
        transformPiece(pieceValue).then(res=>{
            if(res.code===200){
                message.success("兑换碎片成功！")
                handleGetInventory()  //刷新库存
                handleGetUserChance()  //刷新碎片数量
            }
        })
    }

    const [r0Money,setR0Money]=useState(100)
    const [pieceValue,setPieceValue]=useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectValue, setSelectValue] = useState(1);
    const onChange=(value)=>{
        setR0Money(value*100)
        setPieceValue(value)
    }

    const radioOnChange=(e)=>{
        setSelectValue(e.target.value)
    }


    const handleOk = () => {
        // 库存的id 武器的id
        selectWeapon(inventoryId,selectValue).then(res=>{
            if(res.code===200){
                message.success(res.data)
                setIsModalOpen(false);
                handleGetInventory()  //刷新库存
                handleGetUserChance()  //刷新碎片数量
            }
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    useEffect(() => {
        handleGetUserChance()
        handleGetInventory()
    }, []);

    return (
        <div className="shopping">
            <Modal title="选择武器" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800}>
                <Radio.Group onChange={radioOnChange} value={selectValue}>
                <div style={{display: 'flex',marginTop:'15px'}}>

                    <div style={{margin: '10px'}}>
                        <Radio value={22}><Image src={feicueidao} preview={false} style={{width: 200, height: 100}}/></Radio>
                    </div>
                    <div  style={{margin: '10px'}}>
                        <Radio value={23}> <Image src={goldusp} preview={false} style={{width: 200, height: 100}}/></Radio>
                    </div>
                    <div style={{margin: '10px'}}>
                        <Radio value={21}> <Image src={zhijindao} preview={false} style={{width: 200, height: 100}}/></Radio>
                    </div>

                </div>
                </Radio.Group>
            </Modal>


            <div style={{marginTop: '3px', marginLeft: '10px', marginRight: '10px'}}>
                <div className="shoppingTitle">碎片兑换</div>
                <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>
                    <div className="exchangeCard2">
                        <Image src={vip} preview={false} style={{marginTop: '25px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-5px'}}>
                                <div className="exchangeCardText">月度会员</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 20</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(6)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={noshow} preview={false} style={{marginTop: '45px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '25px'}}>
                                <div className="exchangeCardText">隐藏皮肤</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">手枪</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 50</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(3)}>兑换</button>
                        </div>
                    </div>

                    <div className="exchangeCard2">
                        <Image src={noshow} preview={false} style={{marginTop: '45px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '25px'}}>
                                <div className="exchangeCardText">隐藏皮肤</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">刀皮</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 50</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(4)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={noshow} preview={false} style={{marginTop: '45px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '25px'}}>
                                <div className="exchangeCardText">隐藏皮肤</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">AK、M4、AWP</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 200</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(5)}>兑换</button>
                        </div>
                    </div>

                    <div className="exchangeCard2">
                        <Image src={suibei} preview={false} style={{marginTop: '-10px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-2px'}}>
                                <div className="exchangeCardText">水杯</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 80</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(0)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={yixiang} preview={false} style={{marginTop: '-10px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-2px'}}>
                                <div className="exchangeCardText">音响</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 700</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(0)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={shubiao} preview={false} style={{marginTop: '-10px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-2px'}}>
                                <div className="exchangeCardText">鼠标</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 300</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(0)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={shubiaodian} preview={false} style={{marginTop: '-10px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-2px'}}>
                                <div className="exchangeCardText">鼠标垫</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 100</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(0)}>兑换</button>
                        </div>
                    </div>


                    <div className="exchangeCard2">
                        <Image src={jianpan} preview={false} style={{marginTop: '-10px'}}/>
                        <div style={{marginTop: '15px'}}>
                            <div className="exchangeCardDisJust" style={{marginTop: '-2px'}}>
                                <div className="exchangeCardText">键盘</div>
                            </div>
                            <div className="exchangeCardDisJustTop">
                                <div className="exchangeCardTextColor">碎片 * 500</div>
                            </div>
                            <div className="exchangeCardDisJust">
                                <div className="exchangeCardText">我的碎片 * {pieceNum}</div>
                            </div>
                        </div>
                        <div className="exchangeCardDisJustTop">
                            <button className="exchangeBtn" onClick={() => handleExchange(0)}>兑换</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{
                marginTop: '20px',
                marginLeft: '10px',
                marginRight: '10px'
            }}>
                <div className="shoppingTitle">R币兑换碎片</div>
                <div style={{display:'flex'}}>
                    <div>
                        <Image src={r_coin} preview={false} style={{marginTop: '10px', width: '100px', height: '100px'}}></Image>
                        <div style={{marginLeft: '18px', marginTop: '5px'}}>
                            <div style={{marginLeft:'15px',color:'white',fontSize:'16px',fontWeight:'bold',marginTop:'10px'}}>{r0Money}</div>
                        </div>
                    </div>
                    <div style={{marginTop: '45px'}}>
                        <DoubleRightOutlined style={{color: 'white', fontSize: '30px'}}/>
                    </div>
                    <div>
                        <Image src={suipian} preview={false} style={{marginTop: '10px', width: '75px', height: '100px',marginLeft:'10px'}}></Image>
                        <div style={{marginLeft:'18px',marginTop:'5px'}}>
                            <InputNumber className="pieceNumInput" style={{ width: '60px' }} defaultValue={1} onChange={onChange}/>
                        </div>
                    </div>
                </div>
                <button style={{border:'none',width:'90px',height:'30px',backgroundColor:'#00a1d6',borderRadius:'10px',color:'white',marginLeft:'18px'}} onClick={()=>{r0moneyToPiece()}}>兑换碎片</button>



            </div>



            <div style={{
                marginTop: '20px',
                marginLeft: '10px',
                marginRight: '10px'
            }}>
                <div className="shoppingTitle">我的库存</div>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {userStore && userStore.map(userItem => (
                        <>
                            <div className="exchangeCard2">
                                <Image src={returnImg(userItem.img)} preview={false} style={{marginTop: '10px'}}/>
                                <div className="myStoreTextContainer">
                                    <div style={{
                                        color: 'white',
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    }}>{userItem.prizeName}</div>
                                    <div style={{color: 'white', fontSize: '16px', fontWeight: 'bold'}}>可兑换碎片：<span
                                        style={{color: 'red'}}>{userItem.pieceValue}</span></div>
                                </div>

                                <div className="myStoreBtnContainer">
                                    <button className="myStoreBtn" onClick={()=>{exchangeFragments(userItem)}}>兑换碎片</button>
                                    <button className="myStoreBtn" onClick={()=>{exeUseInventory(userItem)}}>使用</button>
                                </div>
                            </div>
                        </>
                    ))}

                </div>
            </div>
        </div>
    )
}
export default Shopping;
