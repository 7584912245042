import http from "./axios";

export const selMyInventory=()=>{
    return http.request({
        url:'/user/selMyInventory',
        method:'get',
    })
}

export const exchangePiece=(id)=>{
    return http.request({
        url:'/shopping/exchangePiece',
        method:'get',
        params:{
            id
        }
    })
}

export const exeUsePiece=(sourceId)=>{
    return http.request({
        url:'/shopping/exeUserUsePiece',
        method:'get',
        params:{
            sourceId,
        }
    })
}

export const exeUserStore=(id)=>{
    return http.request({
        url:'/shopping/exeUseStore',
        method:'get',
        params:{
            id
        }
    })
}

export const transformPiece=(pieceNum)=>{
    return http.request({
        url:'/raffle/transformPiece',
        method:'get',
        params:{
            pieceNum
        }
    })
}

export const selectWeapon=(id,weaponId)=>{
    return http.request({
        url:'/shopping/userSelectWeapon',
        method:'get',
        params:{
            id,
            weaponId
        }
    })
}
